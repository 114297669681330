$(function() {
    function move_backgrounds() {
        windowPosition=$(window).scrollTop()+parseInt($('body').css('margin-top'));
        $(".nasmo-parallax").each(function() {
            elementPosition = $(this).offset();
            if ((windowPosition+originalHeight-parseInt($('body').css('margin-top')))>elementPosition.top) {
                step = (((windowPosition+originalHeight-parseInt($('body').css('margin-top')))-elementPosition.top)*0.3);
                backY = originalHeight-(windowPosition-elementPosition.top+originalHeight-parseInt($('body').css('margin-top')))-initialPosY+step;
                $(this).css('background-position', elementPosition.left+'px '+ backY +'px');
            }
        }); 
        $(".nasmo-bg-parallax").each(function() {
            elementPosition = $(this).offset();
            if ((windowPosition+originalHeight-parseInt($('body').css('margin-top')))>elementPosition.top) {
                step = (((windowPosition+originalHeight-parseInt($('body').css('margin-top')))-elementPosition.top)*0.3);
                backY = originalHeight-(windowPosition-elementPosition.top+originalHeight-parseInt($('body').css('margin-top')))-initialPosY+step;
                $(this).css('background-position', elementPosition.left+'px '+ backY +'px');
            }
        });
    }
    
    function init_parallax() {  
        originalHeight = $(window).outerHeight();
        initialPosY = (originalHeight/3);
        $(".nasmo-parallax").each(function() {
            var Offset = $(this).offset(); 
            Offset.top;
            Offset.left;
            originalTop[$(this).attr('id')] = Offset.top;
            width = $(this).outerWidth( true );
            var box=$(this);   
            box.css('background-repeat','no-repeat').css('background-size', width+'px auto').css('background-attachment','fixed');
        });
        $(".nasmo-bg-parallax").each(function() {
            var Offset = $(this).offset(); 
            Offset.top;
            Offset.left;
            originalTop[$(this).attr('id')] = Offset.top;
            height = $(this).outerHeight( true );
            var box=$(this);   
            box.css('background-repeat','no-repeat').css('background-size', 'cover').css('background-attachment','fixed');
        });

        move_backgrounds();
    }
    
    $(window).scroll(function(){
        move_backgrounds()
    })
    
    var originalTop = [], originalHeight, initialPosY;
    $(function () {
        init_parallax();
    });
    $( window ).resize(function() {
      init_parallax();
    });
});