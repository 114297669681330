$(function() {
	$('#main-nav.menu-site').stellarNav({
		theme: 'light',
		mobileMode: true,

	});

    /*
	$('#main-nav.menu-index').stellarNav({
		theme: 'light',
		mobileMode: true,
		breakpoint: 3000,
	});
    */
	wow = new WOW(
      {
        animateClass: 'animated',
        offset:       100,
        callback:     function(box) {
          console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        }
      }
    );
    wow.init();
    if ($('body').height() < $(window).height()) {$('#footer').addClass('footer-bottom');}
    $(window).on('scroll', function() {
        if ($('body').height() < $(window).height()) {$('#footer').addClass('footer-bottom');} else {$('#footer').removeClass('footer-bottom');}
    });
});


/*$(window).on("scroll", function() {
    if($(window).scrollTop() > 50) {
        $(".nav-new").addClass("active");
    } else {
        //remove the background property so it comes transparent again (defined in your css)
       $(".nav-new").removeClass("active");
    }
});

$(window).on("scroll", function() {
    if($(window).scrollTop() > 50) {
        $("#main-nav").addClass("active");
    } else {
        //remove the background property so it comes transparent again (defined in your css)
       $("#main-nav").removeClass("active");
    }
});*/

$(document).ready(function() {
    $(".lightgallery").lightGallery({
        selector: '.selector'
    });

    $(".lightgalleryref").lightGallery({
        selector: '.selector',
        zoom: false
    });


});